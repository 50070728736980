import { EventWithCommunityAndQuests } from '@xborglabs/ui-shared';
import {
  getJoinStates,
  useMutateParticipateEvent,
} from '@xborglabs/ui-shared/dist/client';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { toast } from '@/lib/toastify';
import { CommunityJoinButton } from '@/modules/community/components/CommunitySidebar/Header/CommunityJoinButton';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';

import classes from './EventCTAButton.module.scss';

export const EventCTAButton = ({
  event,
}: {
  event?: EventWithCommunityAndQuests;
}) => {
  const { mutate: participateEvent, isPending } = useMutateParticipateEvent();
  const { t } = useTranslation(['events', 'errors', 'globals']);
  const joinStates = getJoinStates(event);

  const handleParticipate: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    participateEvent(event!.eventId, {
      onSuccess: () => toast.success(t('events:participate_event_success')),
      onError: () => toast.error('events:participate_event_rejected'),
    });
  };

  if (!event) return null;

  const action = match(event)
    .with({ participation: { isParticipating: true } }, () => ({
      label: '',
      children: <span className="text-in">{t('events:yourIn')}</span>,
      onClick: () => {},
      Component: (props: any) => (
        <div {...props} className={classes.in_button} />
      ),
      disabled: true,
    }))
    .with({ participation: { isParticipating: false } }, () => {
      const payload = {
        onClick: handleParticipate,
        className: 'join-button',
        Component: Button,
        loading: isPending,
      };

      return match([
        Boolean(joinStates?.isJoinAllowed),
        Boolean(joinStates?.eventStarted),
      ])
        .with([true, true], () => ({
          ...payload,
          children: <>{t('events:join')}</>,
          variant: BUTTON_VARIANT.BLUE,
          size: BUTTON_SIZE.LARGE,
          disabled: false,
        }))
        .with([true, false], () => ({
          ...payload,
          children: <>{t('events:preregister')}</>,
          variant: BUTTON_VARIANT.BLUE,
          size: BUTTON_SIZE.LARGE,
          disabled: false,
        }))
        .with([false, true], () => ({
          ...payload,
          children: <>{t('events:join')}</>,
          variant: BUTTON_VARIANT.BLUE,
          size: BUTTON_SIZE.LARGE,
          disabled: true,
        }))
        .with([false, false], () => null)
        .exhaustive();
    })
    .otherwise(() => null);

  if (!action) {
    return <CommunityJoinButton community={event.community} />;
  }

  const { Component, disabled, ...ctaProps } = action;

  return (
    <>
      <Component
        {...ctaProps}
        disabled={disabled || !joinStates?.isJoinAllowed}
      />
    </>
  );
};

import {
  EventWithCommunityAndQuests,
  ResourceType,
  SingleEventType,
  getDate,
  humanize,
} from '@xborglabs/ui-shared';
import {
  EventRank,
  useMutateParticipateEvent,
  useUserProfile,
} from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { match } from 'ts-pattern';

import { Images } from '@/assets/imgs';
import { toast } from '@/lib/toastify';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { Countdown } from '@/modules/shared/components/Molecule/Countdown';
import { LoadingSpinner } from '@/modules/shared/components/Template/Loader';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

type EventRankProps = {
  event: EventWithCommunityAndQuests;
  resourceType: ResourceType | undefined;
};

export const EventRankContainer = ({ event, resourceType }: EventRankProps) => {
  const { t } = useTranslation(['events', 'errors', 'globals']);
  const { data: authState } = useUserProfile();
  return (
    <EventRank event={event}>
      {({ balance, isParticipating, rank }) => (
        <Card variant="overlay" className="event-myrank">
          <div className="flex column middle center">
            {isParticipating ? (
              <div className="flex column gap-small">
                {authState?.displayName ? (
                  <div className="username">{authState?.displayName}</div>
                ) : null}

                <div className="flex middle xp gap-extraSmall">
                  <Image
                    src={
                      resourceType?.images?.resourceImageUrl ??
                      Images.points.points_blue
                    }
                    alt=""
                    width={24}
                    height={24}
                    className="object-fit-contain"
                  />
                  <span>{!!balance ? humanize(balance) : '-'}</span>
                  <span>{resourceType?.name ?? t('globals:xp')}</span>
                </div>
              </div>
            ) : null}
          </div>

          <div className="myrank-overview flex middle center">
            <div className="myrank-details flex column middle center">
              <h3 className="position lexend-heading-l">
                {t('events:position', {
                  count: Number(rank),
                })}
              </h3>
              <span className="percentage lexend-body-xs2">
                {rank
                  ? t('events:top', { percentage: rank })
                  : t('events:noRankYet')}
              </span>
            </div>
            <Image
              src={Images.leaderboard.default}
              alt=""
              width={679}
              height={564}
            />

            <RankingStates event={event} />
          </div>
        </Card>
      )}
    </EventRank>
  );
};

const PARTICIPANT = {
  event: { participation: { isParticipating: true } },
};

const CAN_PARTICIPATE = {
  authState: { isLoggedIn: true },
  event: { participation: { isParticipating: false } },
};

const JOIN_TO_UNLOCK = {
  authState: { isLoggedIn: false },
  event: { participation: { isParticipating: false } },
};

function RankingStates({ event }: { event: SingleEventType }) {
  const { t } = useTranslation(['events', 'errors']);
  const { mutate: participateEvent, isPending } = useMutateParticipateEvent();
  const { data: authState } = useUserProfile();
  const now = getDate();

  const state = match({ event, authState })
    .with(PARTICIPANT, () => null)
    .with(CAN_PARTICIPATE, () => {
      const registerStartDate = getDate(event.registrationStartDate);

      if (registerStartDate.getTime() > now.getTime()) {
        return {
          message: (
            <>
              {t('events:registrationWillStart')}
              <Countdown date={registerStartDate} />
            </>
          ),
          link: '',
        };
      }
      if (getDate(event.registrationEndDate).getTime() < now.getTime()) {
        return {
          message: t('events:registrationEnded'),
          link: '',
        };
      }
      return {
        message: t('events:participate'),
        link: () =>
          participateEvent(event.eventId, {
            onSuccess: () =>
              toast.success(t('events:participate_event_success')),
            onError: () => toast.error('events:participate_event_rejected'),
          }),
      };
    })
    .with(JOIN_TO_UNLOCK, () => {
      if (getDate(event.registrationEndDate).getTime() < now.getTime()) {
        return {
          message: t('events:registrationEnded'),
          link: '',
        };
      }
      return {
        message: t('events:joinToUnlock'),
        link: () => openModal(MODAL.LOGIN, undefined, { login: true }),
      };
    })
    .when(
      ({ event }) =>
        !!event && event.maxParticipants === event.totalParticipants,
      () => ({
        message: t('events:full'),
        link: '',
      }),
    )
    .otherwise(() => null);

  if (!state || !event) return null;

  if (typeof state.link === 'string' && !state.link) {
    return (
      <div className="flex center locked disabled">
        <Icon.lock size={16} className="icon" />
        <span className="lexend-body-s">{state.message}</span>
      </div>
    );
  }

  if (typeof state.link === 'function') {
    return (
      <div className="flex middle center locked gap-small" onClick={state.link}>
        {isPending ? <LoadingSpinner size="xsmall" /> : <Icon.lock size={16} />}
        <span className="lexend-body-s">{state.message}</span>
      </div>
    );
  }

  return (
    <Link href={state.link} className="flex middle center locked">
      <Icon.lock size={16} />
      <span className="lexend-body-s">{state.message}</span>
    </Link>
  );
}
